@import "../_variables.scss";
@import "../_mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600&display=swap");

// .button-login {
//   border: 0px;
//   border-radius: 5px;
//   height: 40px;
//   margin: 10px 0px;
//   width: 80%;
//   background-color: #4d7e7f;
//   color: #fff;
//   cursor: pointer;
// }

.button {
  border: 0px;
  border-radius: 5px;
  height: 40px;
  margin: 10px 0px;
  background-color: #4d7e7f;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-import {
  border: 0px;
  border-radius: 5px;
  height: 40px;
  margin: 10px 0px;
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-btn-normal {
  font-size: 14px;
  // color: #fff;
}
.title-btn-outlined {
  font-size: 14px;
  color: #5baea9;
}

.none-underline {
  text-decoration: none;
}

.btn-main {
  width: 200px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #5baea9;
  border-radius: 5px;
  // color: #fff;
  text-align: center;
  cursor: pointer;
}

.btn-main-outlined {
  width: 200px;
  height: 45px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 5px;
  background-color: transparent;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #5baea9;
  color: #5baea9;
  cursor: pointer;
}
