@import "../_variables.scss";
@import "../_mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600&display=swap");

.text-field {
  input {
    height: 40px;
    width: 100%;
    padding-left: 5px;
    border: 1px solid #a1b0cc;
    border-radius: 5px;
    margin-top: 10px;
  }
}

.search-field {
  display: flex;
  border-radius: 5px;
  border: 1px solid #5baea9;
  align-items: center;
  background: #f4f4f4;

  input {
    width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    padding: 5px 10px;
    font-size: medium;
  }
  input:focus {
    outline: none;
  }
  span {
    margin-right: 10px;
  }
}
.check-box {
  align-items: end;
  display: flex;
  height: 50px;
  .form-check-input:checked {
    background-color: #4d7e7f;
    border-color: #4d7e7f;
  }
  .form-check-input {
    margin-top: 3px;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
}

.radio-check {
  display: block;
  min-height: 1.5rem;
  margin-bottom: 0.125rem;
  height: 50px;
  .form-check-input:checked {
    background-color: #5baea9;
    border-color: #5baea9;
  }
  .form-check-input {
    margin-top: 3px;
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
}

.text-box {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
  height: 60px;
  padding: 0px 10px;
  .title-text-box {
    color: #5baea9;
    font-size: 14px;
    align-items: flex-start;
  }
  .value-text-box {
    background-color: #d3d8dd;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    align-items: center;
    display: flex;
    justify-content: start;
  }
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 60px;
  .title-input {
    color: #5baea9;
    font-size: 14px;
  }
  input {
    width: 100%;
    height: 40px;
    display: flex;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #d3d8dd;
    background: transparent;
  }
  input:focus {
    outline: none;
  }
}
.input-no-underline {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 60px;
  .title-input {
    color: #5baea9;
    font-size: 14px;
  }
  input {
    width: 100%;
    height: 40px;
    display: flex;
    border: none;
  }
  input:focus {
    outline: none;
  }
}

.input-other {
  display: flex;
  flex-direction: column;
  justify-content: end;
  .title-input {
    color: #5baea9;
    font-size: 14px;
  }
  input {
    width: 100%;
    height: 30px;
    display: flex;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #d3d8dd;
  }
  input:focus {
    outline: none;
  }
}

.text-area {
  width: 100%;
  .title-input {
    color: #5baea9;
    font-size: 14px;
  }
  textarea {
    width: 100%;
    min-height: 50px;
    border: 2px solid #d3d8dd;
    border-radius: 5px;
    padding: 5px 10px;
    height: 135px;
  }
  textarea:focus {
    outline: none;
  }
}

.text-area-box {
  width: 100%;
  .title-input {
    color: #5baea9;
    font-size: 14px;
  }
  textarea {
    width: 100%;
    min-height: 50px;
    border: 2px solid #d3d8dd;
    border-radius: 5px;
    padding: 5px 10px;
    height: 100px;
  }
  textarea:focus {
    outline: none;
  }
}

.no-border.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  border-bottom: none !important;
}

input[type="number"].without_number::-webkit-inner-spin-button,
input[type="number"].without_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.ValidateForm {
  input {
    border-bottom: 1px solid #ec2d2d;
  }
}

.search-field-slect {
  padding: 0px 15px;
  margin: 0px 10px;
  border-width: initial;
  border-style: none;
  border-color: none !important;
  outline: none;
  font-size: 14px;
}

.value-textarea-box {
  background-color: #d3d8dd;
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  align-items: center;
  display: flex;
  justify-content: start;
}
