@import "_variables.scss";
@import "_mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600&display=swap");
@import "~bootstrap/scss/bootstrap";

$sidebar-bg-color: #fff;
$sidebar-color: #000;
$icon-bg-color: $primary;
$breakpoint-xl: 1000px;
$submenu-bg-color: #fff;
$highlight-color: $primary;
$sidebar-width: 270px;

@import "~react-pro-sidebar/dist/scss/styles.scss";
.pro-sidebar-header{
  background-color:  #fdf004;
}
.ant-table-thead > tr > th{
  background: #FFC700;
}
body {
  margin: 0;
  height: 100vh;
  color: #353535;
  font-size: 18px;
  background-color: #f4f4f4;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .logo {
    text-align: center;
    padding: 30px 10px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .mt-20 {
    margin-top: 20px;
  }
  .mt-40 {
    margin-top: 40px;
  }
  .mg-10 {
    margin: 10px;
  }
  .pd-10 {
    padding: 10px;
  }

  .col-20 {
    width: 20%;
  }
  .justify-center {
    display: flex;
    justify-content: center;
  }

  .card-view {
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 14px;
    box-shadow: 2px 2px 5px grey;
  }

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;

    color: #222;
    align-items: center;
    justify-content: center;
    display: none;
  }

  .menu-title {
    font-weight: 600;
    font-size: 13px !important;
    .pro-arrow-wrapper {
      visibility: hidden !important;
    }
  }

  .sidebar-profile1 {
    display: flex;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 10px;
    justify-content: center;
    .img-profile {
      width: 50px;
      height: 50px;
      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
        padding-right: 20px;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 20px;

      .info-name {
        font-weight: bold;
        font-size: 13px;
      }
      .info-email {
        font-size: 12px;
        color: #90a0b7;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: sticky;
    font-size: 24px;
    font-weight: bold;
    top: 0;
    z-index: 1;
    background-color: #f4f4f4;
  }

  .no-header {
    display: none;
  }

  .body-page {
    margin: 20px 40px;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  main {
    padding: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    header {
      height: 70px !important;
      width: 100%;
      padding-left: 20px;
      top: 0;
      position: sticky;
      background-color: #aaa;
      display: flex;

      .icon {
        right: 0;
      }
    }

    .body1 {
      padding: 0px;
      min-height: 100%;
    }

    .body2 {
      padding: 0px;
      height: 100vh;
      background-color: $primary;
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-xl) {
    .btn-toggle {
      display: inline-flex;
    }
  }
}
.flex {
  display: flex;
}
.center {
  justify-content: center;
}
.end {
  justify-content: end;
}
.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

.page-container {
  padding: 20px 40px;
}

.menu-title.active {
  color: #5baea9 !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  outline: none !important;
  color: inherit !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: inherit !important;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border-bottom: 2px solid #d9d9d9;
  border-left: none;
  border-top: none;
  border-right: none;
  border-radius: 2px;
  transition: all 0.3s;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #d9d9d9;
  box-shadow: none;
  border-right-width: 1px;
  outline: 0;
}
.ant-input:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #e1f1ef;
  border: 1px solid #e1f1ef;
  border-radius: 30px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-margin-end: 4px;
  margin-inline-end: 4px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 4px;
  padding-inline-end: 4px;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: none;
  border-right-width: 1px;
  outline: 0;
}

.button-Create {
  justify-content: end;
  margin-top: "20px";
  .ant-btn {
    height: 50px;

    color: #ffff;
    background-color: #4d7e7f;
    border-radius: 4px;
    &:hover,
    &:hover {
      color: #fff;
      border-color: #4d7e7f;
      background: #4d7e7f;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4d7e7f;
  border-color: #4d7e7f;
}

.ant-radio-inner::after {
  background-color: #5baea9 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #ebeff2 !important;
}

.validate-input {
  border-bottom: 2px solid #ff0000 !important;
}

.ant-input[disabled] {
  background: #d3d8dd;
  color: #909090 !important;
}

.input-search {
  margin-bottom: 25px;
  border: 1px solid #5baea9;
  border-radius: 4px;
  padding: 20px !important;

  &:focus {
    border-color: #5baea9;
  }

  &:hover {
    border-color: #5baea9;
  }
}
.CheckBox-Group {
  .ant-checkbox-group-item {
    width: 32% !important;
    padding-left: 80px;
  }
  .ant-checkbox + span {
    color: #333333;
  }
}
.inputNumber-custom-treatment {
  width: 100%;
  border: unset;
  border-bottom: 2px solid #d9d9d9;
  font-style: normal;
  font-weight: 500;
  height: 32px !important;
  font-size: 16px !important;
  letter-spacing: 0.01em;
  color: #333333 !important;
  padding: unset !important;
  box-shadow: unset;
}

.ant-input-number.inputNumber-custom-treatment:focus,
.ant-input-number.inputNumber-custom-treatment:hover,
.ant-input-number-focused.inputNumber-custom-treatment {
  border-color: none !important;
  box-shadow: none;
  border-right-width: 1px;
  outline: 0;
  border-bottom: 2px solid #d9d9d9;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  box-shadow: none;
  border-right-width: 1px;
  outline: 0;
}
.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  min-height: 24px;
  opacity: 1;
  margin-top: 3px;
}
/////new
.ant-input,
.ant-picker {
  font-weight: 500 !important;
  font-size: 16px !important;
  letter-spacing: 0.01em;
  color: #323c47 !important;
  height: 36px !important;
}
// .ant-input[disabled] {
//   background: #d3d8dd;
//   color: #333333 !important;
// }
.ant-picker-input > input {
  padding-left: 6px;
}
.ant-picker-input input::placeholder {
  color: #333333;
}
span.ant-picker-suffix {
  font-size: 20px;
  margin: 0 5px;
  color: #5baea9 !important;
}
.ant-select-selection-placeholder,
.ant-picker.ant-picker-disabled {
  font-size: 16px !important;
  letter-spacing: 0.01em;
  color: none;
}
.ant-picker.ant-picker-disabled {
  font-size: 14px !important;
  letter-spacing: 0.01em;
  color: #909090 !important;
  background: #d3d8dd;
}
.ant-checkbox + span {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #323c47;
}
.ant-checkbox-wrapper-checked.price {
  .ant-checkbox,
  .ant-checkbox-checked {
    border-radius: 50% !important;
  }
  // .ant-checkbox-checked .ant-checkbox-inner {
  //   background-color: #5baea9 !;
  //   border-color: #5baea9;
  // }
  .ant-checkbox + span {
    color: #4d7e7f !important;
  }
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: flex !important;
  // background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid !important;
  border-color: #d3d8dd !important;
  padding: 0px !important;
  // border-color: none !important;
  box-shadow: none !important;
}

.create-page{
  background: #fff;
  padding: 1rem 3rem;
  border-radius: 1rem;
}
.leaflet-control-container{
  height: 550px;
}