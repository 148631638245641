@import "../_variables.scss";
@import "../_mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600&display=swap");

.header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  // position: sticky;
  font-size: 24px;
  font-weight: bold;
  top: 0;
  z-index: 1;
  background-color: #f4f4f4;
}

.icon {
  right: 0;
  display: flex;

  .children {
    margin-right: 40px;
  }
}
