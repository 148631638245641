$background-shadow: rgba(0, 0, 0, 0.637);
$primary: #D9D9D9;
$primary-hover: #6ec5bb;
$primary-shadow: #79dace;
$primary-border: #a1b0cc;
$primary-light: #86cfc6;
$primary-light-hover: #e1f1ef;
$primary-btn: #4d7e7f;
$primary-btn-hover: #478e91;
$primary-background: #87d0c9;
$secondary: #909090;
$dark: #252525;
$dark-hover: #414a4c;
$nightrider: #333333;
$midnight: #263544;
$charcoal: #484848;
$charcoal-hover: #d5d5d5;
$gray: #aeaeae;
$gray-border: #ebeff2;
$dimgray: #666666;
$palegray: #c5c7cd;
$lightpalegray: #dfe0eb;
$lightgray: #d3d8dd;
$stronggray: #707683;
$whitesmoke: #f2f2f2;
$danger: #f44336;
$lightmint: rgba(134, 207, 198, 0.25);

$breakpoint-phone: "480px";
$breakpoint-tablet: "769px";
$breakpoint-laptop: "1200px";
