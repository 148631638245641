@import "../_variables.scss";
@import "../_mixin.scss";

.home-page {
  width: 100%;
  height: 100vh;
  background-color: $primary;
  .center-logo {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
