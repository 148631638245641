body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FDF004;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn.loading{
  background-color: gray !important;
}

.btn.loading:hover{
  cursor: wait !important;
}

.text-white{
  color: white;
}

.text-red{
  color: red;
}

.text-black{
  color: black;
}

.text-gray{
  color: gray;
}